import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import { Home } from './home/Home'
import { Uses } from './uses/Uses'

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/uses" component={Uses} />
        </Switch>
      </div>
    </BrowserRouter>
  )
}

export default App

import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles({
  textStyle: {
    fontSize: '12px',
    fontFamily: 'attribute-mono',
    color: '#000000',
    paddingBottom: '3px',
    paddingTop: '3px',
    paddingLeft: '20px',
  },
})

export const Social = ({ site, handle, alt }: SocialProps) => {
  const classes = useStyles()

  return (
    <Typography color="secondary" className={classes.textStyle}>
      @{handle} <em>{site}</em> {alt}
    </Typography>
  )
}

interface SocialProps {
  site: string;
  handle: string;
  alt?: string;
}

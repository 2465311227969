import React from 'react'
import { Link, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles({
  subjectPredicate: {
    fontSize: '18px',
    fontFamily: 'attribute-mono',
  },
  link: {
    color: '#63666A',
  },
})

export const Statement = ({ subject, predicate, link }: StatementProps) => {
  const classes = useStyles()

  return (
    <Typography className={classes.subjectPredicate}>
      {subject} at{' '}
      <Link
        href={link}
        className={classes.link}
        target="_blank"
        underline="always"
      >
        {predicate}
      </Link>
    </Typography>
  )
}

interface StatementProps {
  subject: string;
  predicate: string;
  link: string;
}

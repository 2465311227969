import React from 'react'
import { makeStyles, Link, Typography } from '@material-ui/core'

const useStyles = makeStyles({
  textStyle: {
    fontSize: '12px',
    fontFamily: 'attribute-mono',
    color: '#000000',
    paddingBottom: '3px',
    paddingTop: '3px',
    paddingLeft: '20px',
  },
  link: {
    color: '#63666A',
  },
})

export const Info = ({
  text,
  pendingText,
  link,
  linkText,
  secondaryText,
  target,
}: InfoProps) => {
  const classes = useStyles()

  return (
    <Typography color="secondary" className={classes.textStyle}>
      {text}
      <s>{pendingText}</s>{' '}
      <Link
        href={link}
        className={classes.link}
        target={target || '_blank'}
        underline="always"
      >
        {linkText}
      </Link>{' '}
      {secondaryText}
    </Typography>
  )
}

interface InfoProps {
  text?: string;
  pendingText?: string;
  link?: string;
  linkText?: string;
  secondaryText?: string;
  target?: string;
}

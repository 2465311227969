import {
  createTheme,
  CssBaseline,
  Grid,
  Link,
  makeStyles,
  ThemeProvider,
  Typography,
  IconButton,
} from '@material-ui/core'

import qr from '../../img/qr.jpeg'
import bar from '../../img/bar-gray.png'
import React from 'react'

const useStyles = makeStyles({
  header: {
    marginLeft: 'auto',
    marginRight: 'auto',
    borderTop: '5px solid #FFFFFF',
    minHeight: '55.95px',
  },
  footer: {
    padding: '30px 0',
  },
  subtitle: {
    fontFamily: 'attribute-mono',
    fontSize: '14px',
    color: '#000000',
    paddingRight: '5px',
  },
  gridContainer: {
    padding: '30px',
    maxWidth: '800px',
    marginLeft: 'auto',
    marginRight: 'auto',
    minHeight: '76vh',
  },
  contact: {
    fontFamily: 'attribute-mono',
    fontSize: '10px',
    color: '#000000',
    paddingBottom: '2px',
  },
  infoContainer: {
    paddingTop: '2px',
    paddingLeft: '1px',
  },
  asideBar: {
    float: 'left',
    paddingBottom: '3px',
    paddingTop: '3px',
  },
  textStyle: {
    fontSize: '18px',
    fontFamily: 'attribute-mono',
  },
  subTextStyle: {
    fontSize: '12px',
    fontFamily: 'attribute-mono',
  },
  toolStyle: {
    fontSize: '12px',
    fontFamily: 'attribute-mono',
    color: '#000000',
    paddingBottom: '3px',
    paddingTop: '3px',
    paddingLeft: '20px',
  },
  link: {
    color: '#63666A',
  },
  qr: {
    borderRadius: '50%',
    height: '60px',
    display: 'flex',
    backgroundColor: 'transparent',
  },
})

export const Uses = () => {
  const classes = useStyles()
  const theme = createTheme({
    palette: {
      type: 'light',
      background: {
        default: '#EEEEEE',
      },
      primary: {
        main: '#000000',
      },
      secondary: {
        main: '#000000',
      },
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <header className={classes.header} />
      <div className={classes.gridContainer}>
        <Grid item xs={12} style={{ display: 'flex' }}>
          <Grid container justify="center">
            <Grid xs={12}>
              <IconButton edge="start" disableRipple={true}>
                <Link href="/">
                  <img src={qr} alt="qr" className={classes.qr} />
                </Link>
              </IconButton>
              <div>
                <Typography className={classes.contact}>
                  Inspired by{' '}
                  <Link
                    href="https://rusingh.com/uses/"
                    target="_blank"
                    color="primary"
                    underline="always"
                  >
                    Ru
                  </Link>{' '}
                  -{' '}
                  <Link
                    href="https://news.ycombinator.com/item?id=31199973"
                    target="_blank"
                    color="primary"
                    underline="always"
                  >
                    (via HackerNews article by ecliptik)
                  </Link>
                </Typography>
                <Typography className={classes.textStyle}>Uses</Typography>
                <Typography className={classes.subTextStyle}>
                  Overview of tools that I use on a daily basis
                </Typography>
              </div>
              <br />
              <Typography className={classes.subtitle}>
                Primary Computer & Development
              </Typography>
              <div className={classes.infoContainer}>
                <img
                  src={bar}
                  width="4px"
                  height="118x"
                  className={classes.asideBar}
                  alt="asideBar"
                />
                <Typography color="secondary" className={classes.toolStyle}>
                  <strong>Operating System: </strong>
                  MacOS{' '}
                  <Link
                    href="https://www.apple.com/macbook-pro-14-and-16/"
                    className={classes.link}
                    target="_blank"
                    underline="always"
                  >
                    (Macbook Pro)
                  </Link>
                </Typography>
                <Typography color="secondary" className={classes.toolStyle}>
                  <strong>Terminal & Shell: </strong>
                  <Link
                    href="https://iterm2.com/"
                    className={classes.link}
                    target="_blank"
                    underline="always"
                  >
                    iTerm2
                  </Link>
                  {' + '}
                  <Link
                    href="https://ohmyz.sh/"
                    className={classes.link}
                    target="_blank"
                    underline="always"
                  >
                    zsh
                  </Link>
                </Typography>
                <Typography color="secondary" className={classes.toolStyle}>
                  <strong>IDE: </strong>
                  <Link
                    href="https://code.visualstudio.com/"
                    className={classes.link}
                    target="_blank"
                    underline="always"
                  >
                    VS Code
                  </Link>
                  {' + '}
                  <Link
                    href="https://www.vim.org/"
                    className={classes.link}
                    target="_blank"
                    underline="always"
                  >
                    Vim
                  </Link>
                </Typography>
                <Typography color="secondary" className={classes.toolStyle}>
                  <strong>Browser: </strong>
                  <Link
                    href="https://www.google.com/chrome/"
                    className={classes.link}
                    target="_blank"
                    underline="always"
                  >
                    Chrome
                  </Link>
                </Typography>
                <Typography color="secondary" className={classes.toolStyle}>
                  <strong>Search Engine: </strong>
                  <Link
                    href="https://google.com/"
                    className={classes.link}
                    target="_blank"
                    underline="always"
                  >
                    Google
                  </Link>
                </Typography>
              </div>
              <br />
              <Typography className={classes.subtitle}>Peripherals</Typography>
              <div className={classes.infoContainer}>
                <img
                  src={bar}
                  width="4px"
                  height="118px"
                  className={classes.asideBar}
                  alt="asideBar"
                />
                <Typography color="secondary" className={classes.toolStyle}>
                  <strong>Keyboard: </strong>
                  <Link
                    href="https://fujitsuscannerstore.com/cg01000-296801/"
                    className={classes.link}
                    target="_blank"
                    underline="always"
                  >
                    HHKB Pro (Hybrid)
                  </Link>
                </Typography>
                <Typography color="secondary" className={classes.toolStyle}>
                  <strong>Mouse: </strong>
                  <Link
                    href="https://www.logitech.com/en-us/products/mice/mx-master-3.910-005647.html"
                    className={classes.link}
                    target="_blank"
                    underline="always"
                  >
                    MX Master 3
                  </Link>
                  {' + '}
                  <Link
                    href="https://www.logitechg.com/en-us/products/gaming-mice/g703-wireless-gaming-mouse.910-005091.html"
                    className={classes.link}
                    target="_blank"
                    underline="always"
                  >
                    G703
                  </Link>
                </Typography>
                <Typography color="secondary" className={classes.toolStyle}>
                  <strong>Trackpad: </strong>
                  <Link
                    href="https://www.macrumors.com/2021/06/18/apple-stops-selling-space-gray-magic-accessories/"
                    className={classes.link}
                    target="_blank"
                    underline="always"
                  >
                    Magic Trackpad 2
                  </Link>
                </Typography>
                <Typography color="secondary" className={classes.toolStyle}>
                  <strong>Headphones: </strong>
                  <Link
                    href="https://www.bose.com/en_us/support/products/bose_headphones_support/bose_around_ear_headphones_support/quietcomfort-35-wireless-ii.html"
                    className={classes.link}
                    target="_blank"
                    underline="always"
                  >
                    Bose QC35 II
                  </Link>
                </Typography>
                <Typography color="secondary" className={classes.toolStyle}>
                  <strong>Journal & Pen: </strong>
                  <Link
                    href="https://www.moleskine.com/en-us/shop/notebooks/the-original-notebook/classic-notebook-black-9788883701122.html"
                    className={classes.link}
                    target="_blank"
                    underline="always"
                  >
                    Moleskine
                  </Link>{' '}
                  +{' '}
                  <Link
                    href="https://www.zebrapen.com/products/f-701-all-metal-retractable-ballpoint?_pos=1&_sid=d5f5287c9&_ss=r"
                    className={classes.link}
                    target="_blank"
                    underline="always"
                  >
                    Zebra F-701
                  </Link>
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <footer className={classes.footer} />
    </ThemeProvider>
  )
}
